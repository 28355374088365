<template>
  <v-card outlined class="v-card-outline-primary" elevation="3">
    <v-card-title>
      <h5 class="primary--text">{{ title }}</h5>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" class="pb-0">
          <LocationInput
            v-model="form.location"
            :label="$t('vehicle.address')"
            lg="3"
            validation
            hide-range
            :allowOnlyCountry="false"
          />
        </v-col>
        <v-col cols="12" class="pb-0">
          <DatePicker
            v-model="form.date"
            :label="$t('freight.date')"
            :min="minDate"
            :resetButton="false"
            @input="dirtDateAndTimeChange"
          />
        </v-col>
        <v-col cols="12" md="8" class="pt-0"
          :class="{
             'pb-0': form.enableHour,
             'pb-6': !form.enableHour
          }"
        >
          <div class="d-flex">
            <v-checkbox v-model="form.enableHour" class="mr-4" hide-details>
              <template #label>
                <span>{{ $t('freight.setTime') }}</span>
              </template>
            </v-checkbox>
            <v-checkbox v-model="form.fixedTime" hide-details>
              <template #label>
                <span>FIX</span>
              </template>
            </v-checkbox>
          </div>
        </v-col>
        <v-col cols="12" md="4" class="d-flex justify-end align-center pt-5"
          :class="{
             'pb-0': form.enableHour,
             'pb-6': !form.enableHour
          }"
        >
          <div>
            <span class="text-body-2" v-if="!first || (first && !addNewLocationDisabled)">{{ $t('common.addRemove') }}<v-icon x-small>$arrowRight</v-icon></span>
            <v-icon v-if="!first" size="26" color="primary" class="ml-1" @click="removeHandle">$remove</v-icon>
            <v-icon v-if="!addNewLocationDisabled" size="26" color="primary" class="ml-1" @click="addHandle">$add</v-icon>
          </div>
        </v-col>
        <v-col v-if="form.enableHour" cols="12" class="pb-0 pt-6">
          <HourInput
            v-model="form.from"
            :label="$t('freight.startFrom')"
            :error="startFromError"
            :error-messages="startFromErrorMessage"
            @input="dirtDateAndTimeChange"
          />
        </v-col>
        <v-col v-if="form.enableHour" cols="12">
          <HourInput
            v-model="form.to"
            :label="$t('freight.startTo')"
            :error="startToError"
            :error-messages="startToErrorMessage"
            @input="dirtDateAndTimeChange"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import DatePicker from '@/components/forms/DatePicker'
import HourInput from '@/components/forms/HourInput'
import LocationInput from '@/components/forms/LocationInput'
import * as moment from 'moment'

export default {
  name: 'LocationAdd',
  components: {
    DatePicker,
    HourInput,
    LocationInput
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    first: {
      type: Boolean,
      default: false
    },
    locationType: {
      type: Number,
      default: 0
    },
    value: {
      type: Object,
      default: () => ({
        id: null,
        location: {
          country: null,
          place: {
            city: null,
            postalCode: null,
            latitude: 0,
            longitude: 0,
            range: null
          }
        },
        date: moment().format('YYYY-MM-DD'),
        enableHour: false,
        from: null,
        to: null,
        fixedTime: false
      })
    },
    addNewLocationDisabled: {
      type: Boolean,
      default: true
    },
    minDate: {
      type: String,
      default: ''
    },
    startTime: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    startFromError () {
      if (!this.dirtDateAndTime) {
        return false
      }

      if (moment(this.form.date).unix() === moment().startOf('day').unix()) {
        if (moment().valueOf() > this.form.from) {
          return true
        }
      }

      if (this.form.from > this.form.to) {
        return true
      }

      return false
    },
    startFromErrorMessage () {
      if (!this.dirtDateAndTime) {
        return []
      }

      if (moment(this.form.date).unix() === moment().startOf('day').unix()) {
        if (moment().valueOf() > this.form.from) {
          return this.$t('validation.hourOnlyLaterNow')
        }
      }

      if (this.form.from > this.form.to) {
        return this.$t('validation.invalidRange')
      }
      return []
    },
    startToError () {
      if (!this.dirtDateAndTime) {
        return false
      }

      if (moment(this.form.date).unix() === moment().startOf('day').unix()) {
        if (moment().valueOf() > this.form.to) {
          return true
        }
      }

      if (this.form.from > this.form.to) {
        return true
      }

      return false
    },
    startToErrorMessage () {
      if (!this.dirtDateAndTime) {
        return []
      }

      if (moment(this.form.date).unix() === moment().startOf('day').unix()) {
        if (moment().valueOf() > this.form.to) {
          return this.$t('validation.hourOnlyLaterNow')
        }
      }

      if (this.form.from > this.form.to) {
        return this.$t('validation.invalidRange')
      }

      return []
    }
  },
  data () {
    return {
      form: {
        id: null,
        location: {
          country: null,
          place: {
            city: null,
            postalCode: null,
            latitude: 0,
            longitude: 0,
            range: null
          }
        },
        date: moment().format('YYYY-MM-DD'),
        enableHour: false,
        from: null,
        to: null,
        fixedTime: false
      },
      dirtDateAndTime: false
    }
  },
  watch: {
    value: {
      deep: true,
      handler () {
        this.form = this.value
      }
    },
    form: {
      deep: true,
      handler () {
        this.$emit('input', this.form)
      }
    }
  },
  async created () {
    this.form = this.value
    this.initStartFrom()
    this.initStartTo()
  },
  methods: {
    moment,
    dirtDateAndTimeChange () {
      this.dirtDateAndTime = true
      if (!this.first) {
        return
      }

      if (this.locationType === 0) {
        this.$emit('changeLoadingDate')
      } else {
        this.$emit('changeUnloadingDate')
      }
    },
    initStartFrom () {
      if (this.startTime) {
        const startFrom = moment().startOf('day').add(8, 'hours')
        this.form.from = startFrom.valueOf()
      } else {
        const now = moment()
        const startFrom = moment().startOf('hour').add(15, 'minutes')
        let i = 0
        while (i < 4) {
          if (startFrom.valueOf() > now.valueOf()) {
            this.form.from = startFrom.valueOf()
            return
          } else {
            startFrom.add(15, 'minutes')
          }
          i++
        }
      }
    },
    initStartTo () {
      if (this.startTime) {
        const startFrom = moment().startOf('day').add(8, 'hours')
        this.form.to = startFrom.valueOf()
      } else {
        const now = moment()
        const startTo = moment().startOf('hour').add(15, 'minutes')
        let i = 0
        while (i < 5) {
          if (startTo.valueOf() > now.valueOf()) {
            this.form.to = startTo.valueOf()
            return
          } else {
            startTo.add(15, 'minutes')
          }
          i++
        }
      }
    },
    removeHandle () {
      this.$emit('remove', this.form.id)
    },
    addHandle () {
      if (!this.form.location.country || !this.form.location.place.city) {
        return
      }
      this.$emit('add')
    }
  }
}
</script>
