<template>
  <v-card class="pa-0 mb-0" tile>
    <Loader v-if="loading"/>
    <v-form v-else ref="form" v-model="valid">
      <v-card-title>
        <h4 class="primary--text">
          {{ $t('freight.addFreight') }}
        </h4>
        <v-spacer />
        <div>
          <div class="float-left">
          <v-select class="template-select"
              v-model="selectedTemplate"
              :items="templates"
              item-text="name"
              item-value="name"
              dense
              outlined
              @change="onTemplateChange"
              clearable />
            </div>
          <v-btn v-if="selectedTemplate" :loading="loading" :disabled="loading" color="primary" outlined depressed class="mr-4" @click="deleteTemplate">
            {{ $t('freight.deleteTemplate') }}
          </v-btn>
          <v-btn :loading="loading" :disabled="loading" color="primary" outlined depressed class="mr-4" @click="openSaveTemplate">
            {{ $t('freight.saveTemplate') }}
          </v-btn>
          <v-btn :loading="loading" :disabled="loading" color="primary" outlined depressed class="mr-4" @click="saveDraft">
            {{ $t('freight.saveDraft') }}
          </v-btn>
          <v-btn :loading="loading" :disabled="loading" color="primary" outlined depressed class="mr-4" @click="$router.go(-1)">
            {{ $t('freight.cancel') }}
          </v-btn>
          <v-btn :loading="loading" :disabled="loading" color="primary" outlined depressed @click="addNew">
            {{ $t('freight.submit') }}
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <v-divider class="mt-2 mb-4" />
        <v-row class="mb-0">
          <v-col cols="12" md="6" class="pb-0">
            <LocationAdd
              v-for="(location, index) in form.loadingPlaces"
              :key="index"
              v-model="location.value"
              :first="index === 0"
              :location-type="0"
              :title="$t('freight.loading')"
              :add-new-location-disabled="addNewLoadingPlacesDisabled"
              :min-date="index === 0 ? nowDate : minDateLoading"
              class="mb-6"
              @add="addLoadingPlaces"
              @remove="removeLoadingPlaces"
              @changeLoadingDate="changeLoadingDateHandle"
            />
          </v-col>
          <v-col cols="12" md="6" class="pb-0">
            <LocationAdd
              v-for="(location, index) in form.unloadingPlaces"
              :key="index"
              v-model="location.value"
              :first="index === 0"
              :location-type="1"
              :title="$t('freight.unloading')"
              :add-new-location-disabled="addNewUnloadingPlacesDisabled"
              :min-date="index === 0 ? minDateLoading : minDateUnloading"
              startTime
              class="mb-6"
              @add="addUnloadingPlaces"
              @remove="removeUnladingPlaces"
              @changeUnloadingDate="changeUnloadingDateHandle"
            />
          </v-col>
          <v-col cols="12" class="pt-0">
            <v-card outlined class="v-card-outline-primary" elevation="3">
              <v-card-title>
                <h5 class="primary--text">{{ $t('freight.freightDetails') }}</h5>
              </v-card-title>
              <v-card-text class="pb-6">
              <v-row>
                <v-col cols="12" md="6" class="pb-0">
                  <v-row>
                    <v-col cols="12" md="6" class="pb-0">
                      <label>{{ $t('freight.weight') }}</label>
                      <v-text-field
                        v-model.number="form.freightWeight"
                        type="number"
                        required
                        validate-on-blur
                        single-line
                        dense
                        outlined
                        hide-details="auto"
                        :rules="[required, onlyNaturalNumbers, fractionDigitsValidation]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="pb-0">
                      <label>{{ $t('freight.loadMeters') }}</label>
                      <v-text-field
                        v-model.number="form.loadMeters"
                        type="number"
                        outlined
                        validate-on-blur
                        dense
                        single-line
                        hide-details="auto"
                        :rules="[onlyNaturalNumbers, fractionDigitsValidation]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label>{{ $t('freight.description') }}</label>
                      <v-textarea
                        v-model="form.additionalDescription"
                        outlined
                        required
                        validate-on-blur
                        dense
                        single-line
                        multiple
                        no-resize
                        rows="4"
                        :rules="[maxChars(2000)]"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="12" md="6" class="pb-0">
                      <label>{{ $t('freight.freightCategory') }}</label>
                      <v-select
                        v-model="form.vehicleFreightTypes"
                        ref="vehicleFreightTypes"
                        :items="freightTypes"
                        item-text="description"
                        item-value="id"
                        outlined
                        validate-on-blur
                        dense
                        single-line
                        multiple
                        hide-details="auto"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6" class="pb-0">
                      <label>{{ $t('freight.freightType') }}</label>
                      <v-select
                        v-model="form.freightType"
                        :items="loadingTypes"
                        item-text="description"
                        item-value="id"
                        outlined
                        required
                        validate-on-blur
                        dense
                        single-line
                        multiple
                        hide-details="auto"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <label>{{ $t('freight.volume') }}</label>
                      <v-text-field
                        v-model.number="form.freightVolume"
                        type="number"
                        required
                        validate-on-blur
                        single-line
                        dense
                        outlined
                        hide-details="auto"
                        :rules="[onlyNaturalNumbers, fractionDigitsValidation]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card outlined class="v-card-outline-primary" elevation="3">
            <v-card-title>
              <h5 class="primary--text">{{ $t('freight.vehicleRequirements') }}</h5>
            </v-card-title>
            <v-card-text>
                  <v-row>
                    <v-col cols="12" md="6" class="pb-0">
                      <label>{{ $t('freight.vehicleType') }}</label>
                      <v-select
                        v-model="form.type"
                        ref="type"
                        :items="vehicleTypes"
                        item-text="description"
                        item-value="id"
                        outlined
                        required
                        dense
                        single-line
                        multiple
                        hide-details="auto"
                        :rules="[required]"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6" class="pb-0">
                      <label>{{ $t('freight.size') }}</label>
                      <v-select
                        v-model="form.size"
                        ref="size"
                        :items="vehicleSizeTypes"
                        item-text="description"
                        item-value="id"
                        outlined
                        required
                        validate-on-blur
                        dense
                        single-line
                        hide-details="auto"
                        @input="blurSelect"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" class="pb-0">
                      <label>{{ $t('freight.loadFormType') }}</label>
                      <v-select
                        v-model="form.loadForms"
                        :items="vehicleLoadFormTypes"
                        item-text="description"
                        item-value="id"
                        outlined
                        required
                        validate-on-blur
                        dense
                        single-line
                        multiple
                        hide-details="auto"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="4" class="pb-0">
                      <label>{{ $t('freight.length') }}</label>
                      <v-text-field
                        v-model.number="form.length"
                        type="number"
                        required
                        validate-on-blur
                        single-line
                        dense
                        outlined
                        hide-details="auto"
                        :rules="[onlyNaturalNumbers, fractionDigitsValidation]"
                        @change="calculateVolumeChange"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" class="pb-0">
                      <label>{{ $t('freight.width') }}</label>
                      <v-text-field
                        v-model.number="form.width"
                        type="number"
                        required
                        validate-on-blur
                        single-line
                        dense
                        outlined
                        hide-details="auto"
                        :rules="[onlyNaturalNumbers, fractionDigitsValidation]"
                        @change="calculateVolumeChange"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" class="pb-0">
                      <label>{{ $t('freight.height') }}</label>
                      <v-text-field
                        v-model.number="form.height"
                        type="number"
                        required
                        validate-on-blur
                        single-line
                        dense
                        outlined
                        hide-details="auto"
                        :rules="[onlyNaturalNumbers, fractionDigitsValidation]"
                        @change="calculateVolumeChange"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pb-0">
                      <label>{{ $t('freight.volume') }}</label>
                      <v-text-field
                        v-model.number="form.volume"
                        type="number"
                        outlined
                        required
                        validate-on-blur
                        dense
                        single-line
                        hide-details="auto"
                        :rules="[onlyNaturalNumbers, fractionDigitsValidation]"
                        :disabled="isCalculatedVolume"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <label>{{ $t('freight.options') }}</label>
                      <v-select
                        v-model="form.options"
                        :items="vehicleOptions"
                        item-text="description"
                        item-value="id"
                        outlined
                        required
                        validate-on-blur
                        dense
                        single-line
                        multiple
                        hide-details="auto"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card outlined class="v-card-outline-primary" elevation="3">
                <v-card-title>
                  <h5 class="primary--text">{{ $t('freight.payment') }}</h5>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="12" md="8" class="pb-0">
                          <label>{{ $t('freight.proposedPrice') }}</label>
                          <v-text-field
                            v-model.number="form.price"
                            type="number"
                            outlined
                            required
                            validate-on-blur
                            dense
                            single-line
                            hide-details="auto"
                            :rules="[onlyNaturalNumbers, onlyInteger]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" class="pb-0">
                          <label for="currency">{{ $t('vehicle.currency') }}</label>
                          <v-select
                            v-model="form.currency"
                            id="currency"
                            :items="allCurrencies"
                            item-text="description"
                            item-value="id"
                            outlined
                            required
                            validate-on-blur
                            dense
                            single-line
                            hide-details="auto"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="8" class="pb-0">
                      <label>{{ $t('freight.paymentDeadline') }}</label>
                      <v-select
                        v-model="form.paymentType"
                        ref="paymentType"
                        :items="paymentTypes"
                        item-text="description"
                        item-value="id"
                        validate-on-blur
                        single-line
                        dense
                        outlined
                        hide-details="auto"
                        :rules="[required]"
                        @input="blurSelect"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="4" v-if="form.paymentType === 1" class="pb-0">
                      <label>{{ $t('freight.numberOfDays') }}</label>
                      <v-text-field
                        v-model.number="form.daysForPayment"
                        type="number"
                        validate-on-blur
                        single-line
                        dense
                        outlined
                        hide-details="auto"
                        :rules="[onlyNaturalNumbers, onlyInteger, required]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-2 pb-0">
                        <v-checkbox v-model="privateOffer" class="mt-3" hide-details>
                          <template #label>
                            <span>{{ $t('freight.privateOffer') }}</span>
                          </template>
                        </v-checkbox>
                    </v-col>
                    <template v-if="privateOffer">
                      <v-col cols="12" class="pb-0">
                        <label for="limitedCompanies">{{ $t('freight.limitedCompanies') }}</label>
                        <AutoComplete
                          v-model="form.limitedCompanies"
                          :id="'limitedCompanies'"
                          :items="companies"
                          :item-text="'name'"
                          :item-value="'id'"
                        ></AutoComplete>
                      </v-col>
                      <v-col cols="12">
                        <label for="limitedCompaniesGroups">{{ $t('freight.limitedCompaniesGroups') }}</label>
                        <AutoComplete
                          v-model="form.limitedCompaniesGroups"
                          :id="'limitedCompaniesGroups'"
                          :items="companyGroups"
                          :item-text="'name'"
                          :item-value="'id'"
                        ></AutoComplete>
                      </v-col>
                      <v-col cols="12">
                        <label for="excludedCompaniesGroups">{{ $t('freight.excludedCompaniesGroups') }}</label>
                        <AutoComplete
                          v-model="form.excludedCompaniesGroups"
                          :id="'excludedCompaniesGroups'"
                          :items="companyGroups"
                          :item-text="'name'"
                          :item-value="'id'"
                        ></AutoComplete>
                      </v-col>
                    </template>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
        </v-row>
        <v-divider class="mt-2 mb-4" />
        <v-card-actions v-if="!loading">
          <v-row justify="center">
            <v-col>
              <v-btn :loading="loading" :disabled="loading" class="px-6 float-right" color="primary" outlined depressed @click="$router.go(-1)">
                {{ $t('freight.cancel') }}
              </v-btn>
            </v-col>
            <v-col>
              <v-btn :loading="loading" :disabled="loading" color="primary" outlined depressed @click="addNew">
                {{ $t('freight.submit') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card-text>
    </v-form>
    <v-dialog v-model="templateDialog" width="550" persistent>
      <v-card>
        <v-card-title>{{ $t('freight.saveTemplate') }}</v-card-title>
        <v-card-text>
          <label>{{ $t('freight.templateName') }}</label>
          <v-text-field
            :disabled="loading"
            :loading="loading"
            :rules="templateRules"
            v-model="templateName"
            outlined
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn :loading="loading" :disabled="loading || !templateNameCheck" color="primary" @click="saveTemplate" depressed>{{ $t('common.save') }} AA</v-btn>
          <v-btn :loading="loading" :disabled="loading" outlined @click="closeSaveTemplate" depressed>{{ $t('common.cancel') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Service from '@/services'
import * as moment from 'moment'

import Loader from '@/components/Loader'
import AutoComplete from '@/components/forms/AutoComplete.vue'
import { catchMarketError, createNewPlace } from '@/utils/market'
import { fractionDigitsValidation, roundNumber, generateString } from '@/utils/helpers'
import LocationAdd from '@/views/Market/Freight/LocationAdd'

export default {
  name: 'AddFreight',
  components: {
    Loader,
    AutoComplete,
    LocationAdd
  },
  computed: {
    templateNameCheck () {
      return this.templateName.length <= 20
    },
    allCurrencies () {
      return this.$store.getters.allCurrencies.filter(c => c.isActive)
    },
    copyMode () {
      return this.$route.name === 'CopyFreight'
    },
    vehicleSizeTypes () {
      return this.$store.getters.enums.vehicleSizeTypes
    },
    vehicleLoadFormTypes () {
      return this.$store.getters.enums.vehicleLoadFormTypes
    },
    freightTypes () {
      return this.$store.getters.enums.freightTypes
    },
    paymentTypes () {
      return this.$store.getters.enums.dateOfPayments
    },
    startFromError () {
      if (!this.dirtDateAndTime) {
        return false
      }

      if (moment(this.form.start).unix() === moment().startOf('day').unix()) {
        if (moment().valueOf() > this.form.startFrom) {
          return true
        }
      }

      if (this.form.startFrom > this.form.startTo) {
        return true
      }

      return false
    },
    startFromErrorMessage () {
      if (!this.dirtDateAndTime) {
        return []
      }

      if (moment(this.form.start).unix() === moment().startOf('day').unix()) {
        if (moment().valueOf() > this.form.startFrom) {
          return this.$t('validation.hourOnlyLaterNow')
        }
      }

      if (this.form.startFrom > this.form.startTo) {
        return this.$t('validation.invalidRange')
      }
      return []
    },
    startToError () {
      if (!this.dirtDateAndTime) {
        return false
      }

      if (moment(this.form.start).unix() === moment().startOf('day').unix()) {
        if (moment().valueOf() > this.form.startTo) {
          return true
        }
      }

      if (this.form.startFrom > this.form.startTo) {
        return true
      }

      return false
    },
    startToErrorMessage () {
      if (!this.dirtDateAndTime) {
        return []
      }

      if (moment(this.form.start).unix() === moment().startOf('day').unix()) {
        if (moment().valueOf() > this.form.startTo) {
          return this.$t('validation.hourOnlyLaterNow')
        }
      }

      if (this.form.startFrom > this.form.startTo) {
        return this.$t('validation.invalidRange')
      }

      return []
    },
    dataRangeError () {
      if (moment(this.form.start).unix() > moment(this.form.end).unix()) {
        return true
      }
      return false
    },
    dataRangeErrorMessage () {
      if (moment(this.form.start).unix() > moment(this.form.end).unix()) {
        return this.$t('validation.dateRange')
      }
      return []
    },
    addNewLoadingPlacesDisabled () {
      const result = this.form.loadingPlaces.find(l => !l.value.location.country || !l.value.location.place.city)
      return !!result || this.form.loadingPlaces.length === 5
    },
    addNewUnloadingPlacesDisabled () {
      const result = this.form.unloadingPlaces.find(l => !l.value.location.country || !l.value.location.place.city)
      return !!result || this.form.unloadingPlaces.length === 5
    },
    minDateLoading () {
      return this.form.loadingPlaces[0].value.date
    },
    minDateUnloading () {
      return this.form.unloadingPlaces[0].value.date
    }
  },
  data () {
    return {
      templateRules: [
        (v) =>
          (v && v.length <= 20) ||
          this.$t('freight.templateRule')
      ],
      templateDialog: false,
      templateName: '',
      selectedTemplate: null,
      loading: false,
      valid: true,
      lastVersion: null,
      draftCrated: false,
      intervalSaveAsDraft: null,
      form: {
        loadingPlaces: [
          // {
          //   location: {
          //     country: 'PL',
          //     place: {
          //       city: 'Białystok',
          //       postalCode: '16',
          //       latitude: 53.13248859999999,
          //       longitude: 23.1688403,
          //       range: 0
          //     }
          //   },
          //   date: '2023-11-17T07:22:15.296Z',
          //   from: '8:00',
          //   to: '16:00',
          //   fixedTime: false
          // },
          { value: createNewPlace() }
        ],
        unloadingPlaces: [
          { value: createNewPlace(moment(), 1) }
        ],
        start: moment().format('YYYY-MM-DD'),
        startFrom: null,
        startTo: null,
        end: moment().add(1, 'days').format('YYYY-MM-DD'),
        endFrom: moment().set('hour', 8).set('minutes', 0).valueOf(),
        endTo: moment().set('hour', 8).set('minutes', 0).valueOf(),
        freightName: null,
        freightHeight: null,
        freightWidth: null,
        freightLength: null,
        freightVolume: null,
        freightAmount: null,
        freightWeight: null,
        vehicleFreightTypes: null,
        paymentType: null,
        daysForPayment: null,
        additionalDescription: null,
        size: null,
        type: [],
        load: null,
        loadMeters: null,
        freightType: [],
        loadForms: [],
        height: null,
        width: null,
        length: null,
        volume: null,
        options: [],
        price: null,
        currency: this.$store.state.userCommonData.defaultCurrency,
        limitedCompanies: [],
        limitedCompaniesGroups: [],
        excludedCompaniesGroups: [],
        fixedStart: false,
        fixedEnd: false
      },
      dirtDateAndTime: false,
      enableLoadHour: false,
      enableUnloadHour: false,
      loadingTypes: [],
      vehicleTypes: [],
      vehicleOptions: [],
      required: (v) => (v?.constructor === Array
        ? v.length > 0
        : !!v
      ) || this.$t('validation.fieldRequired'),
      onlyNaturalNumbers: (v) => v ? v > 0 ? true : this.$t('validation.onlyNaturalNumbers') : true,
      onlyInteger: (v) => v ? Number.isInteger(v) ? true : this.$t('validation.onlyInteger') : true,
      fractionDigitsValidation: (v) => fractionDigitsValidation(v, 3) || this.$t('validation.fractionDigitsValidation', { digits: 3 }),
      maxChars: (max) => (v) => v ? v.length <= max ? true : this.$t('validation.maxChar') : true,
      privateOffer: false,
      companyGroups: [],
      templates: [],
      companies: [],
      isCalculatedVolume: false,
      nowDate: moment().format('YYYY-MM-DD')
    }
  },
  async created () {
    this.initStartFrom()
    this.initStartTo()
    await this.fetchData()
    this.form.vehicleFreightTypes = [this.freightTypes[0].id]

    if (this.copyMode) { await this.fetchDetails() }

    this.loading = false
  },
  mounted () {
    const lastPaymentType = localStorage.lastPaymentType || false
    if (lastPaymentType) this.form.paymentType = Number(lastPaymentType)

    const lastDaysForPayment = localStorage.lastDaysForPayment || false
    if (lastDaysForPayment) this.form.daysForPayment = Number(lastDaysForPayment)
  },
  methods: {
    moment,
    async onTemplateChange (templateName) {
      if (templateName === null) {
        this.form = {
          loadingPlaces: [
            { value: createNewPlace() }
          ],
          unloadingPlaces: [
            { value: createNewPlace(moment(), 1) }
          ],
          start: moment().format('YYYY-MM-DD'),
          startFrom: null,
          startTo: null,
          end: moment().add(1, 'days').format('YYYY-MM-DD'),
          endFrom: moment().set('hour', 8).set('minutes', 0).valueOf(),
          endTo: moment().set('hour', 8).set('minutes', 0).valueOf(),
          freightName: null,
          freightHeight: null,
          freightWidth: null,
          freightLength: null,
          freightVolume: null,
          freightAmount: null,
          freightWeight: null,
          vehicleFreightTypes: null,
          paymentType: null,
          daysForPayment: null,
          additionalDescription: null,
          size: null,
          type: [],
          load: null,
          loadMeters: null,
          freightType: [],
          loadForms: [],
          height: null,
          width: null,
          length: null,
          volume: null,
          options: [],
          price: null,
          currency: this.$store.state.userCommonData.defaultCurrency,
          limitedCompanies: [],
          limitedCompaniesGroups: [],
          excludedCompaniesGroups: [],
          fixedStart: false,
          fixedEnd: false
        }
        return
      }

      const orderDetails = this.templates.find(x => x.name === templateName).model
      try {
        const nowDateTime = moment()
        const nowDate = nowDateTime.format('YYYY-MM-DD')

        const loadingPlaces = orderDetails.loadingPlaces.map(place => {
          const result = JSON.parse(JSON.stringify(place))
          delete result.country
          delete result.place
          const date = moment(place.date).format('YYYY-MM-DD')

          result.date = date > nowDate
            ? date
            : nowDate

          result.enableHour = !!result.from

          result.from = moment(nowDate + ' ' + place.from).valueOf()
          result.to = moment(nowDate + ' ' + place.to).valueOf()

          if (result.from < nowDateTime.valueOf() || !result.from) {
            const from = this.getRoundedTimeQuarterHour()
            result.from = from
            result.to = result.from
          }

          return {
            value: {
              ...result,
              id: generateString(),
              location: {
                country: place.country,
                place: place.place
              }
            }
          }
        })

        const unloadingPlaces = orderDetails.unloadingPlaces.map(place => {
          const result = JSON.parse(JSON.stringify(place))
          delete result.country
          delete result.place
          const date = moment(place.date).format('YYYY-MM-DD')

          if (date > nowDate) {
            result.date = date
          } else {
            result.date = nowDateTime.add(1, 'd').format('YYYY-MM-DD')
          }

          result.enableHour = !!result.from
          result.from = moment(nowDate + ' ' + place.from).valueOf()
          result.to = moment(nowDate + ' ' + place.to).valueOf()

          if (!result.from) {
            result.from = moment().set('hour', 8).set('minute', 0).valueOf()
            result.to = result.from
          }

          return {
            value: {
              ...result,
              id: generateString(),
              location: {
                country: place.country,
                place: place.place
              }
            }
          }
        })

        this.form = {
          ...orderDetails,
          loadingPlaces,
          unloadingPlaces
        }

        this.form.limitedCompanies = []
      } catch (e) {
        await catchMarketError(e, true, () => {
          this.$router.push({ name: 'AddFreight' })
        })
      }
    },
    openSaveTemplate () {
      if (this.$refs.form.validate()) {
        this.templateName = ''
        this.templateDialog = true
      }
    },
    async deleteTemplate () {
      this.loading = true
      try {
        var model = {
          name: this.selectedTemplate
        }
        await Service.delete('/FreightOrder/DeleteTemplate', model)
        this.templates = this.templates.filter(x => x.name !== this.selectedTemplate)
        this.selectedTemplate = null
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    closeSaveTemplate () {
      this.templateName = ''
      this.templateDialog = false
    },
    async saveTemplate () {
      if (this.$refs.form.validate()) {
        this.loading = true
        const loadingPlaces = this.form.loadingPlaces.map(i => {
          const result = { ...i.value }
          delete result.location
          return {
            ...result,
            ...i.value.location,
            from: result.enableHour ? moment(result.from).format('HH:mm') : null,
            to: result.enableHour ? moment(result.to).format('HH:mm') : null
          }
        })
        const unloadingPlaces = this.form.unloadingPlaces.map(i => {
          const result = { ...i.value }
          delete result.location
          return {
            ...result,
            ...i.value.location,
            from: result.enableHour ? moment(result.from).format('HH:mm') : null,
            to: result.enableHour ? moment(result.to).format('HH:mm') : null
          }
        })

        if (!this.privateOffer) {
          this.form.limitedCompanies = []
          this.form.limitedCompaniesGroups = []
          this.form.excludedCompaniesGroups = []
        }

        try {
          const model = {
            model: {
              ...this.form,
              loadingPlaces,
              unloadingPlaces
            },
            name: this.templateName
          }
          await Service.post('/FreightOrder/AddTemplate', model)
          this.templates.push(model)
          var newModel = {
            name: this.templateName
          }
          this.selectedTemplate = newModel
        } catch (e) {
          await catchMarketError(e, true, () => {
          })
        } finally {
          this.loading = false
          this.templateName = ''
          this.templateDialog = false
        }
      }
    },
    async fetchData () {
      this.loading = true
      const usersLanguage = this.$store.getters.userCommonData.language
      try {
        const { data: vehicletTypes } = await Service.get('/Dictionary/GetVehicleTypes')
        this.vehicleTypes = vehicletTypes.map(type => {
          const item = {
            id: type.id,
            description: type.translatedValues.find(value => value.language === usersLanguage).name
          }
          return item
        })

        const { data: loadingTypes } = await Service.get('/Dictionary/GetFreightTypes')
        this.loadingTypes = loadingTypes.map(type => {
          const item = {
            id: type.id,
            description: type.translatedValues.find(value => value.language === usersLanguage).name
          }
          return item
        })

        const { data: vehicleOptions } = await Service.get('/Dictionary/GetVehicleOptions')
        this.vehicleOptions = vehicleOptions.map(type => {
          const item = {
            id: type.id,
            description: type.translatedValues.find(value => value.language === usersLanguage).name
          }
          return item
        })

        const { data: templates } = await Service.get('/FreightOrder/GetTemplates')
        this.templates = templates

        const { data: companyGroups } = await Service.get('CompanyGroup/GetAll')
        this.companyGroups = companyGroups.results
        const { data: companies } = await Service.get('Company/GetCompanyNames')
        this.companies = companies
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    roundTimeQuarterHour (dateTime) {
      const result = new Date(dateTime)

      result.setMilliseconds(Math.round(result.getMilliseconds() / 1000) * 1000)
      result.setSeconds(Math.round(result.getSeconds() / 60) * 60)
      result.setMinutes(Math.round(result.getMinutes() / 15) * 15)

      return result
    },
    getRoundedTimeQuarterHour () {
      const now = moment()
      const startTime = moment().startOf('hour').add(15, 'minutes')
      let i = 0
      while (i < 4) {
        if (startTime.valueOf() > now.valueOf()) {
          return startTime.valueOf()
        } else {
          startTime.add(15, 'minutes')
        }
        i++
      }
    },
    async fetchDetails () {
      try {
        const { data: orderDetails } = await Service.get('FreightOrder/CopyFrom', { orderId: this.$route.params.id })
        const nowDateTime = moment()
        const nowDate = nowDateTime.format('YYYY-MM-DD')

        const loadingPlaces = orderDetails.loadingPlaces.map(place => {
          const result = JSON.parse(JSON.stringify(place))
          delete result.country
          delete result.place
          const date = moment(place.date).format('YYYY-MM-DD')

          result.date = date > nowDate
            ? date
            : nowDate

          result.enableHour = !!result.from

          result.from = moment(nowDate + ' ' + place.from).valueOf()
          result.to = moment(nowDate + ' ' + place.to).valueOf()

          if (result.from < nowDateTime.valueOf() || !result.from) {
            const from = this.getRoundedTimeQuarterHour()
            result.from = from
            result.to = result.from
          }

          return {
            value: {
              ...result,
              id: generateString(),
              location: {
                country: place.country,
                place: place.place
              }
            }
          }
        })

        const unloadingPlaces = orderDetails.unloadingPlaces.map(place => {
          const result = JSON.parse(JSON.stringify(place))
          delete result.country
          delete result.place
          const date = moment(place.date).format('YYYY-MM-DD')

          if (date > nowDate) {
            result.date = date
          } else {
            result.date = nowDateTime.add(1, 'd').format('YYYY-MM-DD')
          }

          result.enableHour = !!result.from
          result.from = moment(nowDate + ' ' + place.from).valueOf()
          result.to = moment(nowDate + ' ' + place.to).valueOf()

          if (!result.from) {
            result.from = moment().set('hour', 8).set('minute', 0).valueOf()
            result.to = result.from
          }

          return {
            value: {
              ...result,
              id: generateString(),
              location: {
                country: place.country,
                place: place.place
              }
            }
          }
        })

        this.form = {
          ...orderDetails,
          loadingPlaces,
          unloadingPlaces
        }

        this.form.limitedCompanies = []
      } catch (e) {
        await catchMarketError(e, true, () => {
          this.$router.push({ name: 'AddFreight' })
        })
      }
    },
    async addNew () {
      await this.$refs.form.validate()
      if (!this.valid) return

      localStorage.lastPaymentType = this.form.paymentType
      localStorage.lastDaysForPayment = this.form.daysForPayment

      this.loading = true
      const loadingPlaces = this.form.loadingPlaces.map(i => {
        const result = { ...i.value }
        delete result.location
        return {
          ...result,
          ...i.value.location,
          from: result.enableHour ? moment(result.from).format('HH:mm') : null,
          to: result.enableHour ? moment(result.to).format('HH:mm') : null
        }
      })
      const unloadingPlaces = this.form.unloadingPlaces.map(i => {
        const result = { ...i.value }
        delete result.location
        return {
          ...result,
          ...i.value.location,
          from: result.enableHour ? moment(result.from).format('HH:mm') : null,
          to: result.enableHour ? moment(result.to).format('HH:mm') : null
        }
      })

      if (!this.privateOffer) {
        this.form.limitedCompanies = []
        this.form.limitedCompaniesGroups = []
        this.form.excludedCompaniesGroups = []
      }

      for (const key in this.form) {
        if (typeof this.form[key] === 'string' && this.form[key].length === 0) {
          this.form[key] = null
        }
      }

      try {
        await Service.post('/FreightOrder/AddNew', {
          model: {
            ...this.form,
            loadingPlaces,
            unloadingPlaces
          }
        })
        await this.$router.push({ name: 'Frachts' })
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async saveDraft () {
      if (this.$refs.form.validate()) {
        this.loading = true
        const loadingPlaces = this.form.loadingPlaces.map(i => {
          const result = { ...i.value }
          delete result.location
          return {
            ...result,
            ...i.value.location,
            from: result.enableHour ? moment(result.from).format('HH:mm') : null,
            to: result.enableHour ? moment(result.to).format('HH:mm') : null
          }
        })
        const unloadingPlaces = this.form.unloadingPlaces.map(i => {
          const result = { ...i.value }
          delete result.location
          return {
            ...result,
            ...i.value.location,
            from: result.enableHour ? moment(result.from).format('HH:mm') : null,
            to: result.enableHour ? moment(result.to).format('HH:mm') : null
          }
        })

        if (!this.privateOffer) {
          this.form.limitedCompanies = []
          this.form.limitedCompaniesGroups = []
          this.form.excludedCompaniesGroups = []
        }

        try {
          await Service.post('/FreightOrder/AddDraft', {
            model: {
              ...this.form,
              loadingPlaces,
              unloadingPlaces
            }
          })
          await this.$router.push({ name: 'Frachts' })
        } catch (e) {
        } finally {
          this.loading = false
        }
      }
    },
    addLoadingPlaces () {
      const firstLoadingDate = this.form.loadingPlaces[0].value.date
      this.form.loadingPlaces.push({ value: createNewPlace(firstLoadingDate) })
    },
    addUnloadingPlaces () {
      const firstUnloadingDate = this.form.unloadingPlaces[0].value.date
      this.form.unloadingPlaces.push({ value: createNewPlace(firstUnloadingDate) })
    },
    removeLoadingPlaces (id) {
      if (this.form.loadingPlaces.length <= 1) {
        return
      }
      this.form.loadingPlaces = this.form.loadingPlaces.filter(l => l.value.id !== id)
    },
    removeUnladingPlaces (id) {
      if (this.form.unloadingPlaces.length <= 1) {
        return
      }
      this.form.unloadingPlaces = this.form.unloadingPlaces.filter(l => l.value.id !== id)
    },
    dirtDateAndTimeChange () {
      this.dirtDateAndTime = true
    },
    blurSelect () {
      this.$refs.size.blur()
      this.$refs.paymentType.blur()
    },
    initStartFrom () {
      const now = moment()
      const startFrom = moment().startOf('hour').add(15, 'minutes')
      let i = 0
      while (i < 4) {
        if (startFrom.valueOf() > now.valueOf()) {
          this.form.startFrom = startFrom.valueOf()
          return
        } else {
          startFrom.add(15, 'minutes')
        }
        i++
      }
    },
    initStartTo () {
      const now = moment()
      const startTo = moment().startOf('hour').add(15, 'minutes')
      let i = 0
      while (i < 5) {
        if (startTo.valueOf() > now.valueOf()) {
          this.form.startTo = startTo.valueOf()
          return
        } else {
          startTo.add(15, 'minutes')
        }
        i++
      }
    },
    calculateVolumeChange () {
      if (this.form.width > 0 && this.form.height > 0 && this.form.length > 0) {
        this.calculateVolume()
      } else {
        this.resetVolume()
      }
    },
    calculateVolume () {
      const result = this.form.width * this.form.height * this.form.length
      this.form.volume = roundNumber(result)
      this.isCalculatedVolume = true
    },
    resetVolume () {
      this.form.volume = null
      this.isCalculatedVolume = false
    },
    changeLoadingDateHandle () {
      this.updateLoadingDates()
      this.crossUpdateUnloadingDates()
    },
    changeUnloadingDateHandle () {
      this.updateUnloadingDates()
    },
    updateLoadingDates () {
      this.form.loadingPlaces = this.form.loadingPlaces.map((l, index) => {
        if (index === 0) {
          return l
        }

        const firstDate = moment(this.form.loadingPlaces[0].value.date).valueOf()

        const currentItemDate = moment(l.value.date).valueOf()

        const diff = currentItemDate - firstDate

        if (diff > 0) {
          return l
        }

        l.value.date = this.form.loadingPlaces[0].value.date

        return l
      })
    },
    updateUnloadingDates () {
      this.form.unloadingPlaces = this.form.unloadingPlaces.map((l, index) => {
        if (index === 0) {
          return l
        }

        const firstDate = moment(this.form.unloadingPlaces[0].value.date).valueOf()

        const currentItemDate = moment(l.value.date).valueOf()

        const diff = currentItemDate - firstDate

        if (diff > 0) {
          return l
        }

        l.value.date = this.form.unloadingPlaces[0].value.date

        return l
      })
    },
    crossUpdateUnloadingDates () {
      this.form.unloadingPlaces = this.form.unloadingPlaces.map((l, index) => {
        const firstLoadingDate = moment(this.form.loadingPlaces[0].value.date).valueOf()

        const currentItemDate = moment(l.value.date).valueOf()

        const diff = currentItemDate - firstLoadingDate

        if (diff > 0) {
          return l
        }

        l.value.date = moment(this.form.loadingPlaces[0].value.date).add(1, 'days').format('YYYY-MM-DD')

        return l
      })
    }
  }
}
</script>

<style>
.template-select {
  padding: 0px 10px !important;
}
</style>
